<template>
  <div class="introduceComList">
    <List :numberRow="numberRow" :gutter="gutter">
      <div class="introduce_item" v-for="(item, index) in lists" :key="index" slot="Item">
        <introduceCom :itemType="itemType" :itemData="item"></introduceCom>
      </div>
    </List>
  </div>
</template>

<script>
import introduceCom from './introduceCom.vue';
import List from '../List.vue'
export default {
  name: 'introduceComList',
  components: {
    introduceCom,
    List
  },
  props: {
    numberRow: {
      type: Number,
      default: 2
    },
    gutter: {
      type: Number,
      default: 20
    },
    lists: {
      type: Array,
      default: () => ([])
    },
    //type 为send是在查看简历的投递列表  需要调用投递接口
    //list列表 查看   op 我的分包，有底部按钮，可操作
    itemType: {
      type: String,
      default: 'list'
    },
  },
  data () {
    return {};
  },
  computed: {

  },
};
</script>

<style lang="scss">
</style>
