<template>
  <div class="introduceCom" @click="goDetails">
    <div class="intrduce_top_status_box" v-if="itemType=='send'">
      <div class="ctime_box">
        投递时间：{{ itemData.create_time }}
      </div>
      <div class="intrduce_top_status_text">
        <span v-if="itemData.status == 0">未查看</span>
        <span v-else>已查看</span>
      </div>
    </div>
    <div class="introduceBox">
      <div class="user_img">
        <img :src="itemData.avatar" mode="" />
      </div>
      <div class="introduce_desc">
        <div class="introduce_inner_top_box">
          <div class="user_name">{{ itemData.name }}</div>
          <div class="info_type">{{ teamOrBuild[itemData.type] }}</div>
          <div class="status_box">{{ workStatus[itemData.find_status] }}</div>
        </div>
        <div class="user_info_desc_second">
          <div class="fn-sz-12 fn-cl-999999 mar-right-10">{{ itemData.education }}</div>
          <div class="fn-sz-12 fn-cl-999999 mar-right-10">{{ itemData.work_age }}年工龄</div>
        </div>
        <div class="bids_ep_row_box">
          <div class="bids_ep_row_title">工种：</div>
          <div class="bids_ep_row_text">{{ itemData.category_string }}</div>
        </div>
        <div class="bids_ep_row_box">
          <div class="bids_ep_row_title">期望工作地点：</div>
          <div class="bids_ep_row_text">{{ itemData.area }}</div>
        </div>
      </div>
    </div>
    <div class="user_tags" v-if="itemType=='list'">{{ itemData.desc.slice(0, 10)}}...</div>
  </div>
</template>

<script>
export default {
  name: 'introduceCom',
  props: {
    showTags: {
      type: Boolean,
      default: true
    },
    isCheck: {
      type: Boolean,
      default: true
    },
    itemData: {
      type: Object,
      default: () => ({})
    },
    ///type 为send是在查看简历的投递列表  需要调用投递接口
    //list列表 查看   op 我的分包，有底部按钮，可操作
    itemType: {
      type: String,
      default: 'list'
    },
  },
  data () {
    return {

    };
  },
  methods: {
    goDetails () {
      console.log('thiaoghiwa', this.itemType)
      if (this.itemType == 'send') {
        if (this.itemData.status == 0) {
          this.$api.scanResume({ id: this.itemData.id }).then(res => {
            if (res.code == 1) {
              this.$set(this.itemData, 'status', 1);
              this.$router.push({
                path: '/recruitJob/introduceDetails',
                query: {
                  id: this.itemData.resume_id,
                }
              })
            } else {
              this.$message.error(res.msg);
            }
          });
        } else {
          this.$router.push({
            path: '/recruitJob/introduceDetails',
            query: {
              id: this.itemData.resume_id,
            }
          })
        }
      }
      if (this.itemType == 'list') {
        this.$router.push({
          path: '/recruitJob/introduceDetails',
          query: {
            id: this.itemData.resume_id,
          }
        })
      }
    }
  }
};
</script>

<style lang="scss">
.introduceCom {
  padding: 0 15px 13px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 5px 0px #e4e9ff;
  cursor: pointer;
  transition: transform 0.3s ease;
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 0px 12px 0px #c5c8d5;
  }
  .intrduce_top_status_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 11px;
    padding-top: 15px;
    .ctime_box {
      font-size: 12px;
      font-weight: 400;
      color: #333333;
    }
    .intrduce_top_status_text {
      font-size: 14px;
      font-weight: 400;
      color: #999999;
    }
  }
  .introduceBox {
    display: flex;
    padding-top: 15px;
    .user_img {
      margin-right: 7px;
      img {
        border-radius: 50%;
        width: 48px;
        height: 48px;
      }
    }
    .introduce_desc {
      .introduce_inner_top_box {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        .user_name {
          font-size: 18px;
          margin-right: 8px;
        }
        .info_type {
          font-size: 12px;
          font-weight: 400;
          color: #ff5100;
          width: 42px;
          height: 16px;
          background: #ffede3;
          border-radius: 3px;
          display: flex;
          align-items: center;
          justify-content: center;
          opacity: 1;
          border: 1px solid #ff6e30;
          margin-right: 8px;
        }
        .status_box {
          font-size: 12px;
          font-weight: 400;
          color: #48b957;
          width: 42px;
          height: 16px;
          background: #f2fdf0;
          border-radius: 3px;
          display: flex;
          align-items: center;
          justify-content: center;
          opacity: 1;
          border: 1px solid #48b957;
        }
      }
      .user_info_desc_second {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
      }
      .bids_ep_row_box {
        display: flex;
        align-items: center;
        font-size: 14px;
        margin-bottom: 8px;
        .bids_ep_row_title {
          color: #999999;
        }
        .bids_ep_row_text {
          color: #333333;
        }
      }
    }
  }
  .user_tags {
    background: #f5f6fd;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 400;
    color: #5d72d0;
    line-height: 14px;
    padding: 15px 14px;
    margin-top: 15px;
  }
}
</style>
