<template>
  <div class="job" @click="goDetails">
    <div class="op_top_box" v-if="itemType=='op'">
      <div class="deliver_time">
        投递时间: {{ itemData.create_time }}
      </div>
      <div class="deliver_status">
        已投递
      </div>
    </div>
    <div class="jobs_item_top">
      <div class="jobs_name">
        {{ itemData. name}}
      </div>
      <div class="jobs_pirce" v-if="itemData.calculate_method!=1">
        ￥<span>{{ itemData.salary }}</span> /
        <template v-if="itemData.calculate_method==2">天</template>
        <template v-if="itemData.calculate_method==3">月</template>
      </div>
      <div class="jobs_pirce" v-if="itemData.calculate_method==1">
        <span>面议</span>
      </div>
    </div>
    <div class="jobs_describe">
      <div class="describe_box">
        岗位描述: {{ itemData.desc.slice(0, 10) }}...
      </div>
      <div class="time_box" v-if="itemType=='list'">
        {{ itemData.job_create_time }}
      </div>
    </div>
    <div class="jobs_item_bottom">
      <div class="jobs_info_left">
        <div class="jobs_row_box">
          <div class="jobs_info_title">
            所招岗位:
          </div>
          <div class="jobs_info_text">
            {{ itemData.category_name }}
          </div>
        </div>
        <div class="jobs_row_box">
          <div class="jobs_info_title">
            工作地点:
          </div>
          <div class="jobs_info_text" v-if="itemData.area">
            {{ itemData.area }}
          </div>
          <div class="jobs_info_text" v-else>
            {{ itemData.province + itemData.city + itemData.address }}
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'job',
  props: {
    itemData: {
      type: Object,
      default: () => ({})
    },
    //list列表 查看   op 我的
    itemType: {
      type: String,
      default: 'list'
    }
  },
  data () {
    return {

    };
  },
  methods: {
    goDetails () {
      this.$router.push({
        path: '/recruitJob/workDetails',
        query: {
          id: this.itemData.job_id ? this.itemData.job_id : this.itemData.id,
        }
      })
    }
  }
};
</script>

<style lang="scss">
.job {
  background: #ffffff;
  border-radius: 8px;
  padding: 22px 23px;

  box-shadow: 0px 0px 5px 0px #c5c8d5;
  cursor: pointer;
  transition: transform 0.3s ease;
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 0px 12px 0px #c5c8d5;
  }
  .op_top_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 11px;
    border-bottom: 1px dashed #e5e5e5;
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    margin-bottom: 10px;
    .deliver_time {
    }
    .deliver_status {
    }
  }
  .jobs_item_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
    .jobs_name {
      font-size: 18px;
      font-weight: 500;
      color: #333333;
    }
    .jobs_pirce {
      font-size: 13px;
      font-weight: 500;
      color: #ff5100;
      span {
        font-size: 22px;
      }
    }
  }
  .jobs_describe {
    font-size: 12px;
    font-weight: 400;
    color: #666666;
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    align-items: ce;
  }
  .jobs_item_bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .jobs_info_left {
      .jobs_row_box {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 10px;
        .jobs_info_title {
          color: #999999;
          margin-right: 2px;
        }
        .jobs_info_text {
          color: #333333;
        }
      }
    }
    .right_btn_box {
      display: flex;
      align-items: center;
      gap: 0 10px;
      .small_blue_btn {
        background: #3f63ff;
        border-radius: 5px 5px 5px 5px;
        padding: 10px;
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
      }
      .small_fn_blue_btn {
        background: #ebeefc;
        border-radius: 5px 5px 5px 5px;
        padding: 10px;
        font-size: 14px;
        font-weight: 500;
        color: #3f63ff;
      }
    }
  }
}
</style>
