<template>
    <div class="bar">
        <div @click="clickFun(index)" v-for="(item, index) in tabData" :key="index" class="barItem"
            :class="[item.active ? 'active' : '']">
            {{ item.text }}
        </div>
    </div>
</template>
  
<script>
export default {
    props: ['tabData', 'handleClick'],
    methods: {
        clickFun(index) {
            this.$emit('handleClick', index);
        }
    }

}
</script>
  
<style lang="scss" scoped>
.bar {
    display: flex;
    width: 100%;
    gap: 0 30px;

    .barItem {
        font-size: 18px;
        font-weight: 500;
        color: #333333;
        line-height: 26px;
        border-bottom: 3px solid transparent;
        cursor: pointer;
        padding-bottom: 5px;

        &.active {
            border-bottom: 3px solid #3F63FF;
        }
    }
}
</style>